import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { PrivacyPolicyEn } from './components/PrivacyPolicyEn';
import { PrivacyPolicyFi } from './components/PrivacyPolicyFi';
import { PrivacyPolicyRu } from './components/PrivacyPolicyRu';
import { TermsEn } from './components/TermsEn';
import { TermsFi } from './components/TermsFi';
import { TermsRu } from './components/TermsRu';
import { BlogPageFi } from './components/BlogPages/Fi/BlogPageFi';
import { BlogPageEn } from './components/BlogPages/En/BlogPageEn';
import { BlogPageRu } from './components/BlogPages/Ru/BlogPageRu';
import { FirstBlogEn } from './components/BlogPages/En/opening-soon';
import { FirstBlogFi } from './components/BlogPages/Fi/opening-soon';
import { FirstBlogRu } from './components/BlogPages/Ru/opening-soon';
import { SignUp } from './components/SignUp';

export default class App extends React.Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Routes>
                    <Route exact path='/' element={<Home/>} />
                    <Route path="/privacypolicy_en" element={<PrivacyPolicyEn/>} />
                    <Route path="/privacypolicy_fi" element={<PrivacyPolicyFi/>} />
                    <Route path="/privacypolicy_ru" element={<PrivacyPolicyRu/>} />
                    <Route path="/terms_en" element={<TermsEn/>} />
                    <Route path="/terms_fi" element={<TermsFi/>} />
                    <Route path="/terms_ru" element={<TermsRu/>} />
                    <Route exact path="/blog_en/" element={<BlogPageEn/>} />
                    <Route exact path="/blog_fi/" element={<BlogPageFi/>} />
                    <Route exact path="/blog_ru/" element={<BlogPageRu/>} />
                    <Route path="/blog_en/opening-soon" element={<FirstBlogEn/>} />
                    <Route path="/blog_fi/opening-soon" element={<FirstBlogFi/>} />
                    <Route path="/blog_ru/opening-soon" element={<FirstBlogRu/>} />
                    <Route exact path='/signup' element={<SignUp pca={this.props.pca} />} />
                </Routes>
            </Layout>
        );
    }
}
