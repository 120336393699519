import React, { Component } from 'react';
import { LanguageContext } from './LanguageContext';
import { Link, Redirect } from 'react-router-dom';
import CookieConsent, { Cookies } from "react-cookie-consent";


export default class CookiePopup extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = { loading: false };
    }

    render() {
        const { userLanguage, dictionary } = this.context;
        return (
                <CookieConsent
                location="bottom"
                enableDeclineButton
                declineButtonText={dictionary.CookiePopup.Decline}
                buttonText={dictionary.CookiePopup.Approve}
                setDeclineCookie={false}
                style={{ textAlign: "center", backgroundColor: "#ff6600ff", padding: "7px 0px" }}
                buttonStyle={{ background: "#fff", color: "#000", width: "100px", height: "30px", borderRadius: "8px" }}
                declineButtonStyle={{ background: "#fff", color: "#000", width: "100px", height: "30px", borderRadius: "8px" }}
                >
                {dictionary.CookiePopup.Message}
                    <Link to={"/privacypolicy_" + userLanguage} target="_blank" style={{ paddingLeft: "5px", textDecoration: "underline" }} >{dictionary.CookiePopup.PrivacyPolicy}</Link><br />
                </CookieConsent>
        );
    }
}