import React from 'react';
import { languageOptions, dictionaryList } from './../translations';
import { LanguageContext } from './LanguageContext';

/* User language from local storage */
const defaultLanguage = window.localStorage.getItem('startecon-lang');

/* User Language from browser */
const userLang = (navigator.language || navigator.userLanguage || 'en').substring(0, 2).toLowerCase();
const lang = Object.keys(dictionaryList).indexOf(userLang) > -1 ? userLang : 'en';

export class Layout extends React.Component {
    static displayName = Layout.name;

    state = { userLanguage: defaultLanguage || lang };

    userLanguageChange = selected => {
        const newLanguage = languageOptions[selected] ? selected : "en";
        this.setState({ userLanguage: newLanguage });
        window.localStorage.setItem('startecon-lang', newLanguage);
    }

    updateLanguage = e => this.setState({ language: e.target.value });

    render() {
        const { userLanguage } = this.state;
        return (
            <LanguageContext.Provider value={{
                userLanguage: userLanguage,
                dictionary: dictionaryList[userLanguage],
                userLanguageChange: this.userLanguageChange
            }}>
                {this.props.children}

            </LanguageContext.Provider>
        );
    }
}
