import React from 'react';
import { MsalProvider, MsalAuthenticationTemplate, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { LanguageContext } from './LanguageContext';
import { SignUpForm } from './SignUpForm';

/* Näkymäkohtaiset muuttujat ja vakiot */
const authRequest = {
    scopes: ["openid", "profile"]
};

export class SignUp extends React.Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        // State alustukset
        this.state = {
            loading: true,
        };
    }

    render() {
        const { userLanguage, dictionary } = this.context;

        return (
            <MsalProvider instance={this.props.pca}>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                >
                    <AuthenticatedTemplate>
                        <SignUpForm pca={this.props.pca} />
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <p>{dictionary.SignUp.Unauthenticated}</p>
                    </UnauthenticatedTemplate>
                </MsalAuthenticationTemplate >
            </MsalProvider>
        );
    }
}