import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { authConfig } from './authProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const themeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#800000',
        },
        secondary: {
            main: '#1E1E1E',
        },
        error: {
            main: '#000000',
        },
        success: {
            main: '#1E1E1ECC',
        },
        warning: {
            main: '#D9D9D9',
        },
        info: {
            main: '#fff',
        },
        text: {
            main: '#fff',
        },
    },
    typography: {
        fontFamily: 'Arial Narrow',
    },
    props: {
        MuiTooltip: {
            arrow: true,
        },
    },
};

const theme = createTheme(themeOptions);

theme.typography.body1 = {
    fontWeight: 400,
    [theme.breakpoints.up('xs')]: {
        lineHeight: "24px"
    },
    [theme.breakpoints.up('md')]: {
        lineHeight: "42px"
    },
};

theme.typography.h4 = {
    fontWeight: 600,
    [theme.breakpoints.up('xs')]: {
        padding: "5px 0px 20px 0px",
    },
    [theme.breakpoints.up('md')]: {
        lineHeight: "41.99px",
        padding: "10px 0px 20px 0px",
        letterSpacing: "0.25px"
    },
};

theme.typography.h5 = {
    fontWeight: 400,
    [theme.breakpoints.up('xs')]: {
        lineHeight: "25px"
    },
    [theme.breakpoints.up('md')]: {
        lineHeight: "32px"
    },
};

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const initializePca = async (config) => {
    const pca = await PublicClientApplication.createPublicClientApplication(config);
    return pca;
}

initializePca(authConfig)
    .then(authProvider => {
        root.render(
            <BrowserRouter basename={baseUrl}>
                <ThemeProvider theme={theme}>
                    <App pca={authProvider} />
                </ThemeProvider>
            </BrowserRouter>);

        unregister();
    });



